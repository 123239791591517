import * as React from "react";

import { Box } from "@components/box";
import { Small } from "@components/typography";

// Images
import facebookIcon from "@images/ic-social-facebook.svg";
import twitterIcon from "@images/ic-social-twitter.svg";
import linkedinIcon from "@images/ic-social-linkedIn.svg";
import mailIcon from "@images/ic-social-mail.svg";
import shareIcon from "@images/ic-social-share.svg";

export const ShareArticle = () => {
  const copyArticleURLToClipboard = () => {
    if (typeof window !== "undefined") navigator.clipboard.writeText(window.location.href);
  };

  return (
    <Box display="flex" className="flex-col mt-[4px] md:flex-row md:mt-[0] md:items-center md:space-x-[0.5rem]">
      <Small className="md:hidden">Share the article.</Small>
      <Small className="hidden md:block">Share.</Small>
      <Box display="flex" className="flex-row ml-[-10px]">
        <a
          target="_blank"
          href={`https://www.facebook.com/dialog/share?app_id=203313267132422&display=popup&href=&redirect_uri=}`}
          title="Share on Facebook"
        >
          <img className="w-[46px] h-[46px] md:w-[40px] md:h-[40px]" src={facebookIcon} />
        </a>
        <a target="_blank" href={`https://twitter.com/intent/tweet?text=`} title="Share on Twitter">
          <img className="w-[46px] h-[46px] md:w-[40px] md:h-[40px]" src={twitterIcon} />
        </a>
        <a target="_blank" href={`http://www.linkedin.com/shareArticle?mini=true&url=`} title="Share on LinkedIn">
          <img className="w-[46px] h-[46px] md:w-[40px] md:h-[40px]" src={linkedinIcon} />
        </a>
        <a href={`mailto:?body=`} title="Share by email">
          <img className="w-[46px] h-[46px] md:w-[40px] md:h-[40px]" src={mailIcon} />
        </a>
        <div onClick={() => copyArticleURLToClipboard()} title="Copy to clipboard">
          <img className="w-[46px] h-[46px] md:w-[40px] md:h-[40px]" src={shareIcon} />
        </div>
      </Box>
    </Box>
  );
};
