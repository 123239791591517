import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Box } from "@components/box";
import { GatsbyLink } from "@components/gatsby-link";
import { Paragraph, GrayPhrase } from "@components/typography";

import { LearnArticleLinksProps } from "../types/learn-article-links";

function filterNodesBySectionSlug(data: any, sectionSlug: string) {
  let filteredData: { title: string; slug: string }[] = [];

  data.nodes.forEach((node: any) => {
    if (node.section.slug === sectionSlug) filteredData.push(node);
  });

  return filteredData;
}

export const LearnArticleLinks = (
  props: LearnArticleLinksProps & { data?: any }
) => {
  const { allContentfulLearnArticle }: any = useStaticQuery(graphql`
    query LearnArticlesBySectionSlug {
      allContentfulLearnArticle {
        nodes {
          title
          slug
          section {
            slug
          }
        }
      }
    }
  `);

  const sectionNodes = filterNodesBySectionSlug(
    allContentfulLearnArticle,
    props.sectionSlug
  );

  return (
    <Box className="w-[330px] min-w-[330px] md:hidden">
      <Paragraph className="!font-500">Resources</Paragraph>
      <Box className="mt-[1.25rem] space-y-1.25">
        {sectionNodes.map((node: { title: string; slug: string }, key) => (
          <Paragraph key={key} className="!font-500 text-interface-400">
            {node.slug === props.activeSlug ? (
              <GatsbyLink to={`../${node.slug}`} inheritStyle>
                {node.title}
              </GatsbyLink>
            ) : (
              <GrayPhrase>
                <GatsbyLink to={node.slug} inheritStyle>
                  {node.title}
                </GatsbyLink>
              </GrayPhrase>
            )}
          </Paragraph>
        ))}
      </Box>
    </Box>
  );
};
