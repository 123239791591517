import * as React from "react";
import { graphql } from "gatsby";
import { BLOCKS } from "@contentful/rich-text-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import { Box } from "@components/box";
import { LearnArticleContainer } from "@components/learn-article-container";
import { PageLayout, SEO } from "@components/page-layout";
import { ShareArticle } from "@components/share-article";
import { Heading, PageHeading, Paragraph, Subheading } from "@components/typography";
import { INLINES } from "@contentful/rich-text-react-renderer/node_modules/@contentful/rich-text-types";
import { GatsbyLink } from "@components/gatsby-link";

import arrowRight from "@images/ic-arrow-long-right-gray.svg";

const LearnArticleTemplate = (props: any) => {
  const { contentfulLearnArticle }: any = props.data;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const imgAlt = node.data.target?.description || "iCash image";
        const gatsbyImage = getImage(node.data.target?.gatsbyImageData);
        if (gatsbyImage)
          return (
            <Box display="flex" className="w-full justify-center py-[2rem]">
              <GatsbyImage id={node.data.target.contentful_id} image={gatsbyImage} alt={imgAlt} loading="lazy" />
            </Box>
          );
        return null;
      },
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <Box className="py-[2rem]">
          <PageHeading>{children}</PageHeading>
        </Box>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <Box className="py-[1.5rem]">
          <Heading>{children}</Heading>
        </Box>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <Subheading className="p-0 !text-[17px]">{children}</Subheading>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
        <Paragraph className="pb-[22px] text-interface-300">
          {children}
        </Paragraph>
      ),
      [INLINES.HYPERLINK]: (node: any, children: any) => (
        <GatsbyLink
          target="_blank"
          to={node.data.uri}
          rel={node.data.uri.startsWith("https://icash.ca") ? "noopener" : "noopener nofollow"}
        >
          {children}
        </GatsbyLink>
      ),
      [BLOCKS.UL_LIST]: (_node: any, children: any) => <ul>{children}</ul>
    }
  };

  return (
    <PageLayout>
      <SEO title={contentfulLearnArticle.title} />
      <Box display="flex" className="justify-center sm:justify-start h-[92px] md:h-[61px] bg-interface-100">
        <Box
          display="flex"
          className="justify-between items-center text-[17px] w-full max-w-[1320px] md:text-[14px] md:w-full md:px-[2rem] sm:w-auto"
        >
          <Box
            display="flex"
            className="flex-row whitespace-nowrap overflow-hidden sm:flex-wrap"
            styledCss="text-overflow: ellipsis;"
          >
            <GatsbyLink to="/learn">Learn</GatsbyLink>
            <img src={arrowRight} className="mx-[11px]" />
            <GatsbyLink to="..">{contentfulLearnArticle.section.title}</GatsbyLink>
            <img src={arrowRight} className="mx-[11px]" />
            <Paragraph>{contentfulLearnArticle.title}</Paragraph>
          </Box>
          <Box sm={{ display: "hidden" }}>
            <ShareArticle />
          </Box>
        </Box>
      </Box>
      <LearnArticleContainer
        title={contentfulLearnArticle.title}
        activeSlug={contentfulLearnArticle.slug}
        sectionSlug={contentfulLearnArticle.section.slug}
      >
        {renderRichText(contentfulLearnArticle.body, options)}
      </LearnArticleContainer>
    </PageLayout>
  );
};

export default LearnArticleTemplate;

export const pageQuery = graphql`
  query LearnArticleBySlug($slug: String!) {
    contentfulLearnArticle(slug: { eq: $slug }) {
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
          }
          description
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 950)
        }
      }
      slug
      section {
        slug
        title
      }
      title
    }
  }
`;
