import * as React from "react";

import { Box } from "@components/box";
import { PageHeading } from "@components/typography";

import { LearnArticleLinks } from "./learn-article-links";
import { LearnArticleContainerProps } from "../types";

export const LearnArticleContainer = (
  props: React.PropsWithChildren<LearnArticleContainerProps>
) => (
  <Box display="flex" className="mt-[5.5rem] max-w-[1320px] sm:mt-[2rem] mx-auto">
    <Box display="flex" className="md:flex-col md:w-auto">
      <LearnArticleLinks
        activeSlug={props.activeSlug}
        sectionSlug={props.sectionSlug}
      />
      <Box
        display="flex"
        className="flex-col items-center ml-[5.5rem] lg:ml-[2.75rem] md:mx-[2.75rem] sm:mx-[22px]"
      >
        <PageHeading className="mb-[22px] self-start">{props.title}</PageHeading>
        <Box>{props.children}</Box>
      </Box>
    </Box>
  </Box>
);
